import { useState } from 'react';

import styles from '../signup.module.scss';
import stylesCandidate from '../../candidates/candidateInfo.module.scss';

import resendIcon from '../../../../assets/icons/arrows_circle_blue.svg';
import { confirmSignUp, doLogin, resendConfirmationCode } from '../../../api/userClient';

const VerificationCode = (props) => {

    const { signUpInfo, setStepIndex, handleCancelValidation, appContext } = props;
    const [validationErrors, setValidationErrors] = useState({});
    const [codeInfo, setCodeInfo] = useState({});

    const codeInputs = [
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code" key={1} name="0" maxLength="1" value={ codeInfo[0] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code1" key={2} name="1" maxLength="1" value={ codeInfo[1] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code11" key={3} name="2" maxLength="1" value={ codeInfo[2] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code111" key={4} name="3" maxLength="1" value={ codeInfo[3] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code1111" key={5} name="4" maxLength="1" value={ codeInfo[4] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code11111" key={6} name="5" maxLength="1" value={ codeInfo[5] || "" }/>
    ]

    const handleUserLogin = () => {
        const requestBody = {
            username: signUpInfo.email,
            password: signUpInfo.password,
            platformId: 1005000007
        };
        doLogin(requestBody).then( response => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            const { data } = response;
            if( response.status === 200 && data && data.code && data.code === 100 ) {
                const userInfo = {
                    token: data.result.authInfo.accessToken,
                    userProfile: {
                        ...data.result.userInfo,
                        ...data.result.talentInfo,
                        id: data.result.userInfo.userId,
                        email: signUpInfo.email,
                    }
                }
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                appContext.setUserData(userInfo);
                appContext.setSignUpInfo({
                    name: data.result.userInfo.firstName
                })
                setStepIndex(2);
            } else {
                appContext.setSignUpInfo({ loginFailed: true })
            }
        })
        .catch((res) => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            appContext.setSignUpInfo({ loginFailed: true })
            // setStepIndex(2);
        })
    }

    const handleCodeChange = event => {
        if(event.nativeEvent.data === null) {
            setCodeInfo((prevData) => ({...prevData, [event.target.name]: ""}))
        }
        if(event.target.value.match(/^[0-9]$/)){
            setCodeInfo((prevData) => ({...prevData, [event.target.name]: event.target.value}))
            // actualizando el focus
            if (event.target.id !== "code11111") {
                document.getElementById(event.target.id + 1).focus();
            }
        }
    }

    const validateRequest = () => {
        const errorObject = {};
        if(Object.keys(codeInfo).filter((index) => {
            if(codeInfo[index] !== "") {
                return codeInfo[index]
            }
        }).length < 6) {
            errorObject["code"] = ["El codígo de confirmación debe tener 6 dígitos"]
        };
        return errorObject
    }

    const handleSubmit = () => {
        const validationErrors = validateRequest();
        if (Object.keys(validationErrors).length === 0) {
            appContext.showLoading(true, 'Cargando', stylesCandidate.no_scroll);
            confirmSignUp(signUpInfo.email, Object.keys(codeInfo).map((index) => codeInfo[index]).join('')).then(({status, data}) => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if (status === 200 && data.code === 200) {
                    handleUserLogin();
                } else if (status === 202 && data.code === 400) {
                    setValidationErrors({
                        code: ["Código de verificación incorrecto"]
                    })
                }
            }).catch(error => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if( error.code === 'ERR_NETWORK' ) {
                    appContext.displayNotification(null, true, true);
                } else if(error.response.status === 500) {
                    setValidationErrors({
                        code: ["Código de verificación incorrecto"]
                    })
                }
            })
        } else {
            setValidationErrors(validationErrors);
        }
    }

    const handleResendCode = () => {
        appContext.showLoading(true, 'Cargando', stylesCandidate.no_scroll);
        resendConfirmationCode(signUpInfo.email).then(({status, data}) => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if (status === 200 && data && data.code === 200) {
                appContext.displayNotification('Código de verificación enviado, por favor revisa tu correo electrónico');
            } else {
                console.log('Code not send, cause', data.msg);
                appContext.displayNotification('Código de verificación no enviado, favor de intentar nuevamente', true);
            }
        }).catch(error => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if( error.code === 'ERR_NETWORK' ) {
                appContext.displayNotification(null, true, true);
            }
            console.log(error);
        })
    }

    return (
        <div className={ styles.verification_wrapper }>
            <div className={ styles.text_wrapper }>
                <div className={ styles.main_text }>
                    <p>Por favor, verifica tu direcci&oacute;n de correo electr&oacute;nico.</p>
                </div>
            </div>
            <div className={ styles.instructions_wrapper }>
                <p>Enviamos un c&oacute;digo de verificaci&oacute;n a: <b>{ signUpInfo.email }.</b> Por favor, ingr&eacute;salo para poder continuar.</p>
                <p>Si no recibiste el c&oacute;digo o este ya no es v&aacute;lido, selecciona <b>&quot;Reenviar c&oacute;digo de verificaci&oacute;n&quot;</b> para recibir uno nuevo.</p>
                <p>El c&oacute;digo puede tardar en llegar unos minutos. Si no lo ves en tu bandeja principal, revisa tu carpeta de spam o publicidad.</p>
            </div>
            <div className={ styles.code_wrapper }>
                <p className={ styles.code_label }>C&oacute;digo de verificaci&oacute;n*</p>
                <div className={ styles.code_row } onChange={(event) => handleCodeChange(event) }>
                    {
                        codeInputs.map((codeInput) => codeInput)
                    }
                </div>
                {
                    Object.keys(validationErrors).includes("code") && (
                        <p className={ styles.error_message }>{ validationErrors.code[0] }</p>
                    )
                }
            </div>
            <button className={ styles.resend_button } onClick={ () => handleResendCode() } >
                <img src={ resendIcon } alt="" />
                Reenviar c&oacute;digo de verificaci&oacute;n
            </button>
            <div className={ styles.buttons_wrapper}>
                <button onClick={ () => handleCancelValidation() }>Cambiar direcci&oacute;n de correo</button>
                <button className={ styles.active } onClick={ () => handleSubmit() }>Continuar</button>
            </div>
        </div>
    )
}
export default VerificationCode;